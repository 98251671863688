import { Component, OnDestroy, OnInit } from '@angular/core';
import { isFunction, noop } from 'lodash';
import { combineLatest, iif, of, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { AssetRefreshEnum, MobileApiService } from 'src/app/core/services/mobile-api';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { SectionTypeEnum } from 'src/app/core/services/plaid-link/plaid-link.model';
import { ApplicationTypeEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';

import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import {
	EventDataService,
	EventDataTagActionEnum,
	EventDataTagTypeEnum,
	EventDataTagValueEnum
} from 'src/app/core/services/event-data/event-data.service';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { RoutingHistoryService } from 'src/app/core/services/routing/routing-history.service';

@Component({
	selector: 'op-income-verification',
	templateUrl: './income-verification.component.html',
	styleUrls: ['./income-verification.component.scss']
})
export class IncomeVerificationComponent implements OnInit, OnDestroy {
	constructor(
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private mobileService: MobileApiService,
		private tagDataService: TagDataService,
		private eventDataService: EventDataService,
		private plaidLinkService: PlaidLinkService,
		private dialogService: DialogService,
		private bankAccountService: AchBankAccountsService,
		private routingHistoryService: RoutingHistoryService
	) {}

	protected subscription = new Subscription();

	firstName: string;
	incomePartiallyVerified: boolean;
	incomeVerified: boolean;

	verifiedMonthlyIncomeAmount: string;
	statedMonthlyIncomeAmount: string;
	plaidConnectForIncomeVerified: boolean = true;
	showIncentive: boolean = false;
	sectionType: SectionTypeEnum = SectionTypeEnum.income;
	viewIsReady: boolean = false;
	newMemberDebitCardEligibility = false;

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				tap((loanApp) => {
					const applicant = ApplicantUtils.fromLoanApp(loanApp);
					this.firstName = applicant.getFirstName();
					this.verifiedMonthlyIncomeAmount = this.loanAppService.getCurrentApplicant()?.verifiedMonthlyIncomeAmount;
					this.statedMonthlyIncomeAmount = this.loanAppService.getCurrentApplicant()?.statedMonthlyIncomeAmount;

					const productOfferDetails = ProductOfferDetailsUtils.fromLoanApp(loanApp);
					this.showIncentive = !productOfferDetails?.hasOnlySPL();
				}),
				mergeMap((loanApp) => {
					return combineLatest([
						this.mobileService.getIncomeVerifiedPlaidEligibility(loanApp.id),
						this.bankAccountService.bankAccounts$,
						iif(
							() => loanApp.applicationType === ApplicationTypeEnum.new,
							this.mobileService.getFraudPushToDebitEligibility(loanApp.id),
							of({ eligible: false, success: true })
						)
					]);
				})
			)
			.subscribe({
				next: ([incomeVerifiedPlaidEligibility, bankAccounts, newMemberDebitCardEligibility]) => {
					this.plaidConnectForIncomeVerified = Boolean(incomeVerifiedPlaidEligibility?.eligible);
					this.newMemberDebitCardEligibility = Boolean(newMemberDebitCardEligibility?.eligible);
					this.incomeVerified =
						this.loanAppService.isBtmIncomeVerified() ||
						(this.plaidConnectForIncomeVerified && this.loanAppService.isAutoVerifiedIncome());
					this.incomePartiallyVerified =
						this.loanAppService.isBtmIncomePartialVerified() ||
						(this.plaidConnectForIncomeVerified &&
							this.loanAppService.getCurrentApplicantUtils().getIncomeSourceOptionsOffered());

					this.viewIsReady = true;
					this.sectionType =
						this.plaidConnectForIncomeVerified && this.incomeVerified ? SectionTypeEnum.bank : SectionTypeEnum.income;
					if (
						this.incomeVerified &&
						!this.incomePartiallyVerified &&
						(!this.plaidConnectForIncomeVerified || bankAccounts?.length > 0)
					) {
						this.subscription.unsubscribe();
						this.routingService.routeBtm();
					}
				}
			});
		this.subscription.add(loanAppSub);
		this.logEvents();

		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.onBankConnect(rsp);
			}
		});
		this.subscription.add(plaidSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onBankConnect(event: IBankConnectEvent): void {
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	private tealiumEvent(accountFound: boolean, incomeVerified: boolean): string {
		if (!accountFound) {
			return 'bank_not_found';
		}

		if (!incomeVerified) {
			return 'income_not_found';
		}
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		const accountsFound = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;
		const incomeVerified = event?.data?.foundVerifiedIncome;
		if (incomeVerified) {
			this.loanAppService.updateLoanApplication().subscribe();
		} else {
			const msgData =
				this.sectionType === SectionTypeEnum.bank
					? this.plaidLinkService.getBankDialogMessage(accountsFound)
					: this.plaidLinkService.getIncomeDialogMessage(accountsFound, incomeVerified);
			if (msgData) {
				this.dialogService
					.openMessageDialog(
						msgData,
						() => of(),
						() => of()
					)
					.subscribe({
						next: () => {
							this.tagDataService.link(
								{},
								{
									tealium_event: this.tealiumEvent(accountsFound, incomeVerified)
								}
							);
						}
					});
			}
		}
	}

	bankErrorCallback(event: IBankConnectEvent): void {
		this.dialogService
			.openMessageDialog(
				this.plaidLinkService.getBankDialogMessage(false),
				() => of(),
				() => of()
			)
			.subscribe();
	}

	onNext(event: any): void {
		if (event && this.incomePartiallyVerified) {
			this.mobileService.updateIncomeSelection(event, this.loanAppService.loanApplicationId).subscribe({
				next: () => {
					this.routingService.routeBtm();
				}
			});
		} else {
			this.routingService.routeBtm();
		}
	}

	incomeFilter(element: IAchBankAccount, index: number, array: IAchBankAccount[]): boolean {
		return (
			element.vendorRequestType !== AssetRefreshEnum.plaidAssetRefresh &&
			element.verificationStatus === AccountStatusEnum.verified
		);
	}

	skipPlaid(): void {
		this.routingService.routeBtm();
		this.logEvents(EventDataTagActionEnum.skipEventAction);
	}

	logEvents(event?): void {
		const plaidEvent = {
			eventType:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagTypeEnum.skipEventType
					: EventDataTagTypeEnum.incomePageEventType,
			value1:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagActionEnum.skipEventAction + '_' + this.routingHistoryService.getCurrentRoute()
					: EventDataTagValueEnum.incomePageValue
		};
		const tagEvent = {
			tealium_event:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagTypeEnum.skipEventType
					: EventDataTagTypeEnum.incomePageEventType,
			event_action:
				event == EventDataTagActionEnum.skipEventAction
					? EventDataTagActionEnum.skipEventAction + '_' + this.routingHistoryService.getCurrentRoute()
					: EventDataTagValueEnum.incomePageValue
		};
		this.tagDataService.link({}, tagEvent);
		this.eventDataService.logEventDataToLoanApplication(plaidEvent);
	}
}

<div class="offer-status" *transloco="let t">
	<op-masthead class="header" *ngIf="loaded && !showInstantFundingExperience">
		<div class="op-header-1-font op-mb-12">{{ t('OFFER_STATUS.HEADER.congratulations') }}</div>
		<div class="op-header-3-font headerText">
			{{
				numberOfOffers
					| opSingularPluralString
						: t('OFFER_STATUS.HEADER.chooseYourOffer', { name: applicant?.firstName })
						: t('OFFER_STATUS.HEADER.chooseYourOffers', { name: applicant?.firstName })
			}}
		</div>
	</op-masthead>

	<op-masthead class="header" *ngIf="loaded && showInstantFundingExperience">
		<div class="op-header-1-font op-mb-12">
			{{
				numberOfOffers
					| opSingularPluralString
						: t('OFFER_STATUS.HEADER.congratsOnYourOffer')
						: t('OFFER_STATUS.HEADER.congratsOnYourOffers')
			}}
		</div>
		<div class="op-left">
			<div class="disclaimerText op-micro-font" *ngIf="!areAllProductsApproved">
				{{ t('OFFER_STATUS.instantFundingDisclaimer') }}
			</div>
		</div>
	</op-masthead>

	<op-content-container class="content" *ngIf="loaded">
		<div class="op-left" *ngIf="!showInstantFundingExperience">
			<div class="disclaimerText op-micro-font" *ngIf="!areAllProductsApproved">{{ t('OFFER_STATUS.disclaimer') }}</div>
		</div>
		<op-promo-card
			class="clearfix op-mtb-20"
			[promoKey]="'OFFER_STATUS.DEBIT_CARD_PROMO.message'"
			*ngIf="showDebitCardMessage && showInstantFundingExperience"
			[showRightArrow]="true"
			(click)="showDebitCardFaq()"
		>
		</op-promo-card>
		<op-unsecured-personal-loan-pre-approved
			*ngIf="preApprovedUnsecuredPersonalLoan"
			[product]="preApprovedUnsecuredPersonalLoan"
			(continue)="onSubmit($event)"
			[showBankConnectedMessage]="showBankConnectedMessage"
		></op-unsecured-personal-loan-pre-approved>
		<op-secured-personal-loan-approved
			*ngIf="approvedSecuredPersonalLoan"
			[product]="approvedSecuredPersonalLoan"
			(continue)="onSubmit($event)"
			[showBankConnectedMessage]="showBankConnectedMessage"
		></op-secured-personal-loan-approved>
		<op-unsecured-personal-loan-approved
			*ngIf="approvedUnsecuredPersonalLoan"
			[product]="approvedUnsecuredPersonalLoan"
			(continue)="onSubmit($event)"
			[showBankConnectedMessage]="showBankConnectedMessage"
			[plaidIncentiveApplied]="plaidIncentiveApplied"
		></op-unsecured-personal-loan-approved>
		<op-secured-personal-loan-eligible
			*ngIf="!isMetaOrganization && eligibleSecuredPersonalLoan"
			[product]="eligibleSecuredPersonalLoan"
			[isSplOnlyLoan]="isSplOnlyLoan"
			(continue)="onSubmit($event)"
			(notSureAboutOffer)="goToOfferStatusInformation($event)"
			[showBankConnectedMessage]="showBankConnectedMessage"
		></op-secured-personal-loan-eligible>
		<op-secured-personal-loan-eligible
			*ngIf="!isMetaOrganization && securedDeclinedPersonalLoan"
			[product]="securedDeclinedPersonalLoan"
			[isSplOnlyLoan]="isSplOnlyLoan"
			(continue)="onSubmit($event)"
			(notSureAboutOffer)="goToOfferStatusInformation($event)"
			[showBankConnectedMessage]="showBankConnectedMessage"
		></op-secured-personal-loan-eligible>
		<op-secured-personal-loan-eligible-pw
			*ngIf="isMetaOrganization && eligibleSecuredPersonalLoan"
			[product]="eligibleSecuredPersonalLoan"
			(continue)="onSubmit($event)"
			[isSplOnlyLoan]="isSplOnlyLoan"
			(notSureAboutOffer)="goToOfferStatusInformation($event)"
			[showBankConnectedMessage]="showBankConnectedMessage"
		></op-secured-personal-loan-eligible-pw>
		<op-secured-personal-loan-pre-approved
			*ngIf="preApprovedSecuredPersonalLoan"
			[product]="preApprovedSecuredPersonalLoan"
			[isSplOnlyLoan]="isSplOnlyLoan"
			(continue)="onSubmit($event)"
			[showBankConnectedMessage]="showBankConnectedMessage"
		></op-secured-personal-loan-pre-approved>

		<div *ngIf="areAllProductsApproved" class="op-mt-20 op-left">
			<p class="personalizeLoanTermsText op-header-3-font" [innerHTML]="t('OFFER_STATUS.personalizeLoanTerms')"></p>
		</div>

		<div class="op-pb-40"></div>
		<op-expansion-panel
			id="whatIsSecuredPanel"
			*ngIf="showWhatIsSPLMsg"
			[titleBold]="false"
			[title]="t('OFFER_STATUS.SECURED_OFFERED.whatIsASecuredPersonalLoan')"
			[opTagClick]="'What is a secured loan?'"
		>
			<p>{{ t('OFFER_STATUS.WHAT_IS_A_SECURED_PERSONAL_LOAN.one') }}</p>
			<p
				*ngIf="isElectronicTitleEnabledState"
				[innerHTML]="t('OFFER_STATUS.WHAT_IS_A_SECURED_PERSONAL_LOAN.twoElectronicTitleReq')"
			></p>
			<p *ngIf="!isElectronicTitleEnabledState" [innerHTML]="t('OFFER_STATUS.WHAT_IS_A_SECURED_PERSONAL_LOAN.two')"></p>
		</op-expansion-panel>

		<op-expansion-panel
			id="loanDifferencesPanel"
			*ngIf="showLoanDifferencesMsg"
			[titleBold]="false"
			[title]="t('OFFER_STATUS.LOAN_DIFFERENCES.title')"
			[opTagClick]="'What are the differences of UPL and SPL?'"
		>
			<h2>{{ t('OFFER_STATUS.LOAN_DIFFERENCES.subTitle') }}</h2>
			<p>{{ t('OFFER_STATUS.LOAN_DIFFERENCES.one') }}</p>
			<p>{{ t('OFFER_STATUS.LOAN_DIFFERENCES.two') }}</p>
			<p>{{ t('OFFER_STATUS.LOAN_DIFFERENCES.three') }}</p>
			<p>{{ t('OFFER_STATUS.LOAN_DIFFERENCES.four') }}</p>
		</op-expansion-panel>

		<op-expansion-panel
			id="vehicleRequirementsPanel"
			*ngIf="showVehicleReqMsg"
			[titleBold]="false"
			[title]="t('OFFER_STATUS.SPL_AND_VEHICLE_REQUIREMENTS.title')"
			[opTagClick]="'Vehicle requirements'"
		>
			<ul>
				<li>{{ t('OFFER_STATUS.SPL_AND_VEHICLE_REQUIREMENTS.first') }}</li>
				<li>{{ t('OFFER_STATUS.SPL_AND_VEHICLE_REQUIREMENTS.second') }}</li>
				<li id="splVehReqNoteFour">
					{{ t('OFFER_STATUS.SPL_AND_VEHICLE_REQUIREMENTS.third', { customerStateName: customerStateName }) }}
				</li>
				<li id="splVehReqNoteFive">
					{{
						t('OFFER_STATUS.SPL_AND_VEHICLE_REQUIREMENTS.fourth', {
							numOfDaysToRenewRegistration: numOfDaysToRenewRegistration
						})
					}}
				</li>
				<li>{{ t('OFFER_STATUS.SPL_AND_VEHICLE_REQUIREMENTS.fifth') }}</li>
			</ul>
		</op-expansion-panel>
		<op-how-it-works-panel id="howItWorksPanel" *ngIf="!areAllProductsApproved"></op-how-it-works-panel>

		<op-approved-loan-additional-info-panels *ngIf="areAllProductsApproved"></op-approved-loan-additional-info-panels>

		<div class="op-pt-40"></div>
	</op-content-container>
</div>

import { HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SKIP_ERROR_RETRY } from '../../interceptors/error.interceptor';
import { SKIP_BUSY_INDICATOR } from '../../interceptors/mobile-api-busy.interceptor';
import { ICcasUser } from '../ccas/ccas.model';
import { EnvironmentService } from '../environment/environment.service';
import { ILoanApplication, ILoanApplicationStatus } from '../loan-application/loan-application.model';
import { ApiUrlService } from './api-url.service';
import {
	AssetRefreshEnum,
	FeatureNameEnum,
	FileTypeEnum,
	IAccountsMeResult,
	IAchBankAccountResult,
	IAdditionalContact,
	IAdditionalContactResult,
	IAdditionalInfo,
	IAddressResult,
	IAgentResult,
	IApplicantConsentResult,
	IApplicantResult,
	IBankAccountDecryptTokenResult,
	ICassAddress,
	ICassAddressResult,
	ICheckAddressChange,
	IClaimOfferResult,
	IClosesStoreResult,
	IConsentRequest,
	ICreditAuthorization,
	ICreditAuthorizationResult,
	IDebitCard,
	IDebitCardDetails,
	IDebt,
	IDebtResult,
	IDisbursementAchResult,
	IDisbursementOptions,
	IDisbursementResult,
	IDocumentStatus,
	IEligibilityResult,
	IEsignDisclosuresDecryptTokenResult,
	IFeatureEligibilityResult,
	IFinances,
	IFinancesResult,
	IFinancesUpdate,
	IFindApplication,
	IFindApplicationResult,
	IFindELetters,
	IFindELettersResult,
	IFTPDecryptTokenResult,
	IGetIncomeResult,
	IHomeSummaryResult,
	IIncome,
	IIncomeResult,
	IIncomeSelectionResult,
	ILasso,
	ILassoResult,
	ILeadsByNameAddress,
	ILeadsByNameAddressResult,
	ILoanTermsResult,
	IMultiFactor,
	IMultiFactorResult,
	IMultiProductResult,
	INewApplication,
	INewApplicationResult,
	INotificationCompleteResult,
	IOfferCodeResult,
	IPartnerLead,
	IPartnerLeadConsent,
	IPaymentOptions,
	IPaymentOptionsResult,
	IPaymentReminders,
	IPaymentRemindersOptionsResult,
	IPaymentRemindersResult,
	IPhoneResult,
	IPlaidConnectedInstitutions,
	IPlaidConnectionComplete,
	IPlaidConnectionCompleteResult,
	IPlaidLinkTokenResult,
	IPlaidRefreshRequest,
	IProductAction,
	IProductStatusAction,
	IRachBankAccount,
	IRchAutopayActiveStatus,
	IReference,
	IReferenceResult,
	IReferralCodeResult,
	IReferralCodeStatusResult,
	IResult,
	ISaveLoanTerms,
	ISecuredOfferAction,
	ISetAchBankAccountResult,
	ISetAddress,
	ISetAddressResult,
	ISetBank,
	ISetPhone,
	ISetPhoneResult,
	ISubmitResult,
	IUpdateBank,
	IUpdateEsignSignerStatus,
	IUpdateEsignSignerStatusResult,
	IUpdateIncome,
	IUploadDocumentQueryParams,
	IUploadDocumentResult,
	IUploadDocumentV2Result,
	IValidateRoutingNumberResult,
	IVerifyFactor,
	IVerifyFactorResult,
	IZipCodeLookUpResult,
	IZipCodeResult,
	ProductCategorySelectionEnum,
	IPlaidCreateUserResult,
	IPlaidCraDetailsResult
} from './mobile-api.model';

/**
 * Service with all the http requests.
 *
 * Parameter order convention
 * 1st parameters specific to the rest-api
 * 2nd loanId: number
 * 3rd applicantIndex: number = 0
 *
 * FUnction naming convention is based on HTTP request.
 * GET => get
 * POST => set
 * PUT => update
 * DELETE => delete
 *
 * @export
 * @class MobileApiService
 */
@Injectable({
	providedIn: 'root'
})
export class MobileApiService extends ApiUrlService {
	userAccounts: number;
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	zipCodeLookUp(zipCode: string): Observable<IZipCodeLookUpResult> {
		return this.http.get<IZipCodeLookUpResult>(
			`${this.API_MOBILE_SERVICIABLE_URL}/zipcodeLookup/${zipCode}` /* cspell: disable-line */
		);
	}

	checkZipCode(
		zipCode: string,
		promoCode: string = null,
		lastName: string = null
	): Observable<IZipCodeResult | IOfferCodeResult> {
		let queryParams;
		if (lastName && promoCode) {
			queryParams = { zipCode, lastName, promoCode };
		} else {
			queryParams = { zipCode };
		}

		const params = new HttpParams({ fromObject: { ...queryParams } });
		return this.http.get<IZipCodeResult | IOfferCodeResult>(`${this.API_MOBILE_URL}/leads/search`, { params });
	}

	checkPartnerLead(leadId: number): Observable<IPartnerLead> {
		return this.http.get<IPartnerLead>(`${this.API_MOBILE_URL}/leads/${leadId}/`);
	}

	getPartnerLeadDetails(leadId: number): Observable<IPartnerLead> {
		return this.http.get<IPartnerLead>(`${this.API_MOBILE_V2_URL}/lead/${leadId}`);
	}

	updatePartnerFinderConsent(partnerLeadConsent: IPartnerLeadConsent): Observable<IPartnerLead> {
		return this.http.post<IPartnerLead>(`${this.API_MOBILE_V2_URL}/lead/consent`, partnerLeadConsent);
	}

	getClosesStore(
		zipCode: string,
		numberOfStores: number = 10,
		loanApplicationId: number = null
	): Observable<IClosesStoreResult> {
		const queryParams: any = { numberOfStores: String(numberOfStores) };
		if (loanApplicationId > 0) {
			queryParams.loanApplicationId = loanApplicationId;
		}
		const params = new HttpParams({ fromObject: { ...queryParams } });
		return this.http.get<IClosesStoreResult>(`${this.API_MOBILE_URL}/closest_store/${zipCode}`, { params });
	}

	findApplication(search: IFindApplication): Observable<IFindApplicationResult> {
		return this.http.post<IFindApplicationResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/search`, search);
	}

	findLetter(search: IFindELetters): Observable<IFindELettersResult> {
		return this.http.post<IFindELettersResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/letters/find`, search);
	}

	getELetter(letterType: string, letterMetadataId: number, loanApplicationId: number): Observable<any> {
		return this.http.get<any>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/letters/get-document?letterType=
			${letterType}&letterMetadataId=${letterMetadataId}&loanApplicationId=${loanApplicationId}`,
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	newApplication(newApp: INewApplication, refId?: string): Observable<INewApplicationResult> {
		const options = refId ? { headers: new HttpHeaders().append('refId', refId) } : undefined;
		return this.http.post<INewApplicationResult>(`${this.API_MOBILE_V3_LOAN_APP_URL}/newApplication`, newApp, options);
	}

	newSecuredApplication(newApp: INewApplication): Observable<INewApplicationResult> {
		return this.http.post<INewApplicationResult>(`${this.API_MOBILE_V3_LOAN_APP_URL}`, newApp);
	}

	lassoCapture(newApp: ILasso): Observable<ILassoResult> {
		return this.http.post<ILassoResult>(`${this.API_MOBILE_LOAN_APP_URL}/lasso/capture`, newApp);
	}

	getLoanApplication(loanId: number, showBusy: boolean = true): Observable<ILoanApplication> {
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, !showBusy);
		return this.http.get<ILoanApplication>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}`, { context });
	}

	getLoanApplicationStatus(loanId: number): Observable<ILoanApplicationStatus> {
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, true);
		return this.http.get<ILoanApplicationStatus>(`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/status`, { context });
	}

	getClientsReferrals(clientId: string): Observable<IReferralCodeResult> {
		return this.http.get<IReferralCodeResult>(`${this.API_MOBILE_URL}/clients/${clientId}/referrals`);
	}

	getReferralCodeStatus(referralCode: number): Observable<IReferralCodeStatusResult> {
		return this.http.get<IReferralCodeStatusResult>(`${this.API_MOBILE_URL}/referral-code/${referralCode}/status`);
	}

	getAgent(agent: string): Observable<IAgentResult> {
		return this.http.get<IAgentResult>(`${this.API_MOBILE_V1_URL}/agent/${agent}/channels`);
	}

	setAdditionalInfo(additionalInfo: IAdditionalInfo, loanId: number, applicantIndex = 0): Observable<any> {
		return this.http.post<any>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/additional-info`,
			additionalInfo
		);
	}

	multiFactor(data: IMultiFactor): Observable<IMultiFactorResult> {
		return this.http.post<IMultiFactorResult>(`${this.API_MOBILE_V2_URL}/mfa/multiFactor`, data);
	}

	verifyFactor(data: IVerifyFactor, refId?: string): Observable<IVerifyFactorResult> {
		const options = refId ? { headers: new HttpHeaders().append('refId', refId) } : undefined;
		return this.http.post<IVerifyFactorResult>(`${this.API_MOBILE_V2_URL}/mfa/verifyFactor`, data, options);
	}

	getGoogleApiPlaces(callback: string): Observable<any> {
		return this.http.get<any>(`api/protected/resource/google.api.places/?callback=${callback}&libraries=places`, {
			responseType: 'text' as 'json'
		});
	}

	getAddresses(loanId: number, applicantIndex = 0, currentOnly = false): Observable<IAddressResult[]> {
		const params = new HttpParams().set('currentOnly', String(currentOnly));
		return this.http.get<IAddressResult[]>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/addresses`,
			{ params }
		);
	}

	getCassAddress(address: Partial<ICassAddress>): Observable<ICassAddressResult> {
		const params = new HttpParams({ fromObject: { ...address } });
		return this.http.get<ICassAddressResult>(`${this.API_MOBILE_LOAN_APP_URL}/getCassAddress`, { params });
	}

	getAddressPreCheck(
		address: Partial<ISetAddress>,
		loanApplicationId: number,
		applicantIndex = 0
	): Observable<ICheckAddressChange> {
		return this.http.post<ICheckAddressChange>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanApplicationId}/applicants/${applicantIndex}/addresses/address-pre-check`,
			address
		);
	}

	updateAddresses(
		address: Partial<ISetAddress>,
		addressId: string,
		loanId: number,
		applicantIndex = 0
	): Observable<ISetAddressResult> {
		return this.http.put<ISetAddressResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/addresses/${addressId}`,
			address
		);
	}

	setAddresses(address: Partial<ISetAddress>, loanId: number, applicantIndex = 0): Observable<ISetAddressResult> {
		return this.http.post<ISetAddressResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/addresses`,
			address
		);
	}

	setSignOut(): Observable<IResult> {
		return this.http.post<IResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/signout`, {}); /* cspell: disable-line */
	}

	getPhone(loanId: number, applicantIndex = 0): Observable<IPhoneResult> {
		return this.http.get<IPhoneResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/phone`);
	}

	setPhone(phone: ISetPhone, loanId: number, applicantIndex = 0): Observable<ISetPhoneResult> {
		return this.http.put<ISetPhoneResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/phone`,
			phone
		);
	}

	getApplicant(loanId: number, applicantIndex = 0): Observable<IApplicantResult> {
		return this.http.get<IApplicantResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}`);
	}

	updateApplicant(app: INewApplication, loanId: number, applicantIndex = 0): Observable<ILoanApplication> {
		return this.http.put<ILoanApplication>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}`,
			app
		);
	}

	setCreateAuthorization(
		creditAuth: ICreditAuthorization,
		loanId: number,
		applicantIndex = 0
	): Observable<ICreditAuthorizationResult> {
		return this.http.post<ICreditAuthorizationResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/credit_auth`,
			creditAuth
		);
	}

	setFinances(finances: IFinances, loanId: number, applicantIndex = 0): Observable<IFinancesResult> {
		return this.http.post<IFinancesResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/finances`,
			finances
		);
	}

	updateFinances(finances: IFinancesUpdate, loanId: number, applicantIndex = 0): Observable<IFinancesResult> {
		return this.http.put<IFinancesResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/finances`,
			finances
		);
	}

	getFinances(loanId: number, applicantIndex = 0): Observable<IFinancesResult> {
		return this.http.get<IFinancesResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/finances`
		);
	}

	getDebt(loanId: number, applicantIndex = 0): Observable<IDebtResult[]> {
		return this.http.get<IDebtResult[]>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/debt`);
	}

	setDebt(debt: IDebt, loanId: number, applicantIndex = 0): Observable<IDebtResult> {
		return this.http.post<IDebtResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/debt`,
			debt
		);
	}

	deleteDebt(debtId: number, loanId: number, applicantIndex = 0): Observable<IDebtResult> {
		return this.http.delete<IDebtResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/debt/${debtId}`
		);
	}

	updateDebt(debt: IDebt, loanId: number, applicantIndex = 0): Observable<IDebtResult> {
		return this.http.put<IDebtResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/debt/${debt.id}`,
			debt
		);
	}

	getIncome(loanId: number, applicantIndex = 0): Observable<IGetIncomeResult[]> {
		return this.http.get<IGetIncomeResult[]>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/income`
		);
	}

	setIncome(income: IIncome, loanId: number, applicantIndex = 0): Observable<IIncomeResult> {
		return this.http.post<IIncomeResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/income`,
			income
		);
	}

	deleteIncome(incomeId: number, loanId: number, applicantIndex = 0): Observable<IIncomeResult> {
		return this.http.delete<IIncomeResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/income/${incomeId}`
		);
	}

	updateIncome(income: IUpdateIncome, loanId: number, applicantIndex = 0): Observable<IIncomeResult> {
		return this.http.put<IIncomeResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/income/${income.id}`,
			income
		);
	}

	getReferences(loanId: number, applicantIndex: number = 0): Observable<IReferenceResult[]> {
		return this.http.get<IReferenceResult[]>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/references`
		);
	}

	setReference(reference: IReference, loanId: number, applicantIndex: number = 0): Observable<IReferenceResult> {
		return this.http.post<IReferenceResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/references`,
			reference
		);
	}

	updateReference(reference: IReference, loanId: number, applicantIndex: number = 0): Observable<IReferenceResult> {
		return this.http.put<IReferenceResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/references/${reference.id}`,
			reference
		);
	}

	deleteReference(referenceId: number, loanId: number, applicantIndex: number = 0): Observable<IResult> {
		return this.http.delete<IResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/references/${referenceId}`
		);
	}

	updateDataComplete(
		loanId: number,
		applicantIndex = 0,
		securityCode?: string,
		creditBureau?: string
	): Observable<ILoanApplication> {
		const params = {
			securityCode: securityCode || undefined,
			creditBureau: creditBureau || undefined
		};

		return this.http.put<ILoanApplication>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/data_complete`,
			params
		);
	}

	/**
	 * When pre-approved
	 *
	 * @param {number} loanId
	 * @param {boolean} accept
	 * @return {*}  {Observable<anu>}
	 * @memberof MobileApiService
	 */
	updateDisbursementAch(accept: boolean, loanId: number): Observable<IDisbursementAchResult> {
		const params = {
			acceptAch: accept
		};
		return this.http.put<IDisbursementAchResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/disbursement-ach`, params);
	}

	/**
	 * When prequal-accepted
	 *
	 * @return {*}  {Observable<ILoanApplication>}
	 * @memberof MobileApiService
	 */
	updateIdentity(loanId: number): Observable<ILoanApplication> {
		return this.http.post<ILoanApplication>(
			`${this.API_MOBILE_V3_URL}/prequal/loan-applications/${loanId}/verify-identity`,
			{}
		);
	}

	/**
	 * To update the income as stated or verified
	 *
	 * @param {number} loanId
	 * @param {boolean} deactivate
	 * @return {*}  {Observable<any>}
	 * @memberof MobileApiService
	 */
	updateIncomeSelection(
		incomeSelection: string,
		loanId: number,
		applicantIndex: number = 0
	): Observable<IIncomeSelectionResult> {
		const params = {
			incomeType: incomeSelection?.toUpperCase()
		};
		return this.http.put<IIncomeSelectionResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/incomeSelection`,
			params
		);
	}

	/**
	 * When bounced
	 *
	 * @param {number} loanId
	 * @param {boolean} deactivate
	 * @return {*}  {Observable<any>}
	 * @memberof MobileApiService
	 */
	updateDisbursement(deactivate: boolean, loanId: number): Observable<IDisbursementResult> {
		const params = {
			deactivate: deactivate
		};
		return this.http.put<IDisbursementResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/disbursements`, params);
	}

	setUploadDocument(
		formData: FormData,
		queryParams: Partial<IUploadDocumentQueryParams>,
		loanId: number,
		applicantIndex: number = 0
	): Observable<HttpEvent<IUploadDocumentResult>> {
		const params = new HttpParams({ fromObject: { ...queryParams } });
		const context = new HttpContext().set(SKIP_ERROR_RETRY, true);

		return this.http.post<IUploadDocumentResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/documents`,
			formData,
			{
				params,
				reportProgress: true,
				observe: 'events',
				context
			}
		);
	}

	setUploadDocumentV2(
		formData: FormData,
		queryParams: Partial<IUploadDocumentQueryParams>,
		loanId: number,
		applicantIndex: number = 0,
		showBusy: boolean = true
	): Observable<HttpEvent<IUploadDocumentV2Result>> {
		const params = new HttpParams({ fromObject: { ...queryParams } });
		const context = new HttpContext().set(SKIP_ERROR_RETRY, true);
		context.set(SKIP_BUSY_INDICATOR, !showBusy);

		return this.http.post<IUploadDocumentV2Result>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/documents`,
			formData,
			{
				params,
				reportProgress: true,
				observe: 'events',
				context
			}
		);
	}

	setUploadPhotos(
		formData: FormData,
		queryParams: Partial<IUploadDocumentQueryParams>,
		loanId: number,
		applicantIndex: number = 0,
		showBusy: boolean = true
	): Observable<HttpEvent<IUploadDocumentResult>> {
		const params = new HttpParams({ fromObject: { ...queryParams } });
		const context = new HttpContext().set(SKIP_ERROR_RETRY, true);
		context.set(SKIP_BUSY_INDICATOR, !showBusy);
		return this.http.post<IUploadDocumentResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/photos`,
			formData,
			{
				params,
				reportProgress: true,
				observe: 'events',
				context
			}
		);
	}

	getDocumentsStatus(type: FileTypeEnum, loanId: number, applicantIndex: number = 0): Observable<IDocumentStatus[]> {
		const params = type ? new HttpParams().set('type', type) : undefined;
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, true);
		return this.http.get<IDocumentStatus[]>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/documents/status`,
			{ params, context }
		);
	}

	deleteUploadedDocument(loanId: number, applicantIndex: number = 0): Observable<any> {
		return this.http.delete<any>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/documents`);
	}

	deleteUploadedDocumentV2(loanId: number, applicantIndex: number = 0, showBusy: boolean = true): Observable<IResult> {
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, !showBusy);
		return this.http.delete<IResult>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/documents`,
			{ context }
		);
	}

	updateRoutingNumberValidation(routingNumber: string, loanId: number): Observable<IValidateRoutingNumberResult> {
		return this.http.put<IValidateRoutingNumberResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/routingNumber/${routingNumber}/validate`,
			{}
		);
	}

	setAchBankAccount(bank: ISetBank, loanId: number, applicantIndex: number = 0): Observable<ISetAchBankAccountResult> {
		return this.http.post<ISetAchBankAccountResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/ach_bank_account`,
			bank
		);
	}

	setRachBankAccount(bank: IAchBankAccountResult, loanId: number): Observable<IRchAutopayActiveStatus> {
		return this.http.put<IRchAutopayActiveStatus>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/rach-bank-account?rachAccountId=${bank.id}`,
			{}
		);
	}

	addRachBankAccount(bank: ISetBank, loanId: number, applicantIndex: number = 0): Observable<IAchBankAccountResult> {
		return this.http.post<IAchBankAccountResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/rach-bank-account`,
			bank
		);
	}

	getRachBankAccount(loanId: number): Observable<IAchBankAccountResult> {
		return this.http.get<IAchBankAccountResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/rach_bank_account`);
	}

	getRachBankAccounts(loanAppId: number): Observable<IAchBankAccountResult[]> {
		return this.http.get<IAchBankAccountResult[]>(`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanAppId}/rach_bank_accounts`);
	}

	verifyRachBankAccount(
		bankAccountInfo: IRachBankAccount,
		loanAppId: number,
		applicantIndex: number = 0
	): Observable<boolean> {
		return this.http
			.put(
				`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanAppId}/applicants/${applicantIndex}/bank_account_verification`,
				bankAccountInfo
			)
			.pipe(map((verifyResult: { status?: string }) => verifyResult?.status?.toUpperCase() === 'ACTIVE'));
	}

	setRachAccount(loanAppId: number, bankAccountInfo: IRachBankAccount): Observable<IRachBankAccount> {
		return this.http.post(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanAppId}/bank_account`,
			bankAccountInfo
		) as Observable<IRachBankAccount>;
	}

	updateRachAccount(loanAppId: number, rachAccountId: number): Observable<IRachBankAccount> {
		const params = new HttpParams().set('rachAccountId', rachAccountId);
		return this.http.put(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanAppId}/rach_account`,
			{},
			{ params }
		) as Observable<IRachBankAccount>;
	}

	getAchBankAccount(loanId: number): Observable<IAchBankAccountResult> {
		return this.http.get<IAchBankAccountResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/ach_bank_account`);
	}

	getAchBankAccounts(loanId: number): Observable<IAchBankAccountResult[]> {
		return this.http.get<IAchBankAccountResult[]>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/ach_bank_accounts`);
	}

	getAchBankAccountsForApplicant(loanId: number, applicantIndex: number = 0): Observable<IAchBankAccountResult[]> {
		return this.http.get<IAchBankAccountResult[]>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/ach_bank_account`
		);
	}

	updateAchBankAccount(updateBank: IUpdateBank, loanId: number): Observable<IResult> {
		const queryParams = {
			bankAccountId: String(updateBank.bankAccountId),
			customerConfirms: String(updateBank.customerConfirms)
		};
		const params = new HttpParams({ fromObject: { ...queryParams } });
		return this.http.put<IResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/disbursement_account`, {}, { params });
	}

	updatePostApprovalAchBankAccount(bankAccountId: number, loanId: number): Observable<IResult> {
		const queryParams = {
			bankAccountId: String(bankAccountId)
		};
		const params = new HttpParams({ fromObject: { ...queryParams } });
		return this.http.put<IResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/postApproval/disbursement_account`,
			{},
			{ params }
		);
	}

	setSubmit(vehicleRegistrationUploaded: boolean, loanId: number): Observable<IResult> {
		const additionInfo = {
			vehicleRegistrationUploaded: vehicleRegistrationUploaded
		};
		return this.http.post<IResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/submit`, additionInfo);
	}

	setSubmitV2(loanId: number): Observable<ISubmitResult> {
		const body = {
			vehicleRegistrationUploaded: false
		};
		return this.http.post<ISubmitResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/submit`, body);
	}

	setEligibility(
		featureName: FeatureNameEnum,
		productCategorySelection: ProductCategorySelectionEnum,
		loanId: number,
		applicantIndex: number = 0
	): Observable<IEligibilityResult | IFeatureEligibilityResult> {
		const body = {
			featureName: featureName,
			productCategorySelection: productCategorySelection
		};
		return this.http.post<IEligibilityResult>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/eligibility`,
			body
		);
	}

	getApplicantConsent(loanId: number, applicantIndex: number = 0): Observable<IApplicantConsentResult> {
		return this.http.get<IApplicantConsentResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/applicant_consent`
		);
	}

	getLinkToken(bankOnly: boolean, loanId: number, applicantIndex: number = 0): Observable<IPlaidLinkTokenResult> {
		const body = bankOnly ? {} : { bankIncomeProduct: true };
		return this.http.post<IPlaidLinkTokenResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/plaid/link-token`,
			body
		);
	}

	/**
	 * connects bank and income
	 *
	 * @param {IPlaidConnectionComplete} connectionInfo
	 * @param {number} loanId
	 * @param {number} [applicantIndex=0]
	 * @return {*}  {Observable<IPlaidConnectionCompleteResult>}
	 * @memberof MobileApiService
	 */
	setPlaidIncome(
		connectionInfo: IPlaidConnectionComplete,
		loanId: number,
		applicantIndex: number = 0
	): Observable<IPlaidConnectionCompleteResult> {
		return this.http.post<IPlaidConnectionCompleteResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/plaid/income`,
			connectionInfo
		);
	}

	/**
	 * only connects bank
	 *
	 * @param {IPlaidConnectionComplete} connectionInfo
	 * @param {number} loanId
	 * @param {number} [applicantIndex=0]
	 * @return {*}  {Observable<IPlaidConnectionCompleteResult>}
	 * @memberof MobileApiService
	 */
	setPlaidBankConnect(
		connectionInfo: IPlaidConnectionComplete,
		loanId: number,
		applicantIndex: number = 0
	): Observable<IPlaidConnectionCompleteResult> {
		return this.http.post<IPlaidConnectionCompleteResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/plaid/bank-account`,
			connectionInfo
		);
	}

	getLoanTerms(productCategory: string, loanId: number): Observable<ILoanTermsResult> {
		const query = { productCategory };
		const params = new HttpParams({ fromObject: { ...query } });
		return this.http.get<ILoanTermsResult>(`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/loan_terms`, {
			params
		});
	}

	getPaymentOptions(paymentOptions: IPaymentOptions, loanId: number): Observable<IPaymentOptionsResult[]> {
		const params = new HttpParams({ fromObject: { ...paymentOptions } });
		return this.http.get<IPaymentOptionsResult[]>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/payment_options`,
			{
				params
			}
		);
	}

	updateSaveLoanTerms(saveLoanTerms: ISaveLoanTerms, loanId: number): Observable<any> {
		return this.http.put<any>(`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/save_loan_terms`, saveLoanTerms);
	}

	getAdditionalContact(loanId: number, applicantIndex: number = 0): Observable<IAdditionalContactResult> {
		return this.http.get<IAdditionalContactResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/additional_contact`
		);
	}

	updateAdditionalContact(
		additionalContact: IAdditionalContact,
		loanId: number,
		applicantIndex: number = 0
	): Observable<IAdditionalContactResult> {
		return this.http.put<IAdditionalContactResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/additional_contact`,
			additionalContact
		);
	}

	getVerifyBankAccount(account: number, loanId: number): Observable<any> {
		return this.http.get<any>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/verify_bank_account/${account}`);
	}

	updateNotificationComplete(loanId: number): Observable<INotificationCompleteResult> {
		return this.http.put<INotificationCompleteResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/notification_complete`,
			{}
		);
	}

	updateConfirmLoanTerms(loanId: number): Observable<IResult> {
		return this.http.put<IResult>(`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/confirm_loan_terms`, null);
	}

	updateRachBankAccount(bankAccountId: number, acceptAch: boolean, loanId: number): Observable<IResult> {
		return this.http.put<IResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/rach_bank_account`, {
			bankAccountId,
			acceptAch
		});
	}

	setSecuredAppOffer(securedAppOfferAction: ISecuredOfferAction, loanId: number): Observable<any> {
		return this.http.post<any>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/securedAppOffer`, securedAppOfferAction);
	}

	setProductSelection(requestParameters: any, loanId: number): Observable<any> {
		return this.http.post<any>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/product_selection`,
			requestParameters
		);
	}

	getLeadsByNameAndAddress(leadInfo: ILeadsByNameAddress): Observable<ILeadsByNameAddressResult> {
		const params = new HttpParams({ fromObject: { ...leadInfo } });
		return this.http.get<ILeadsByNameAddressResult>(`${this.API_MOBILE_URL}/leads/leadsByNameAndAddress`, {
			params
		});
	}

	/**
	 * Renew auth token for eSign customers who need more time to complete the signing beyond our timeout limit
	 */
	getEsignTokenRenewal(loanApplicationId: string, applicantId: string, token: string, event: string): Observable<any> {
		const params = new HttpParams({ fromObject: { applicantId, event, loanApplicationId, token } });

		return this.http.get<any>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanApplicationId}/applicants/${applicantId}/token_renewal`,
			{
				params
			}
		);
	}

	/*
	 * Save the current eSign signer status for a specific loan application and applicant.
	 *
	 * @param requestParameters
	 * @param loanApplicationId
	 */
	updateEsignSignerStatus(
		requestParameters: IUpdateEsignSignerStatus,
		loanId: number
	): Observable<IUpdateEsignSignerStatusResult> {
		const { applicantId } = requestParameters;

		return this.http.put<IUpdateEsignSignerStatusResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantId}/document_status`,
			requestParameters
		);
	}

	setCcasAccount(emailAddress: string): Observable<any> {
		return this.http.post<any>(`${this.API_MOBILE_V2_LOAN_APP_URL}/accounts`, {
			emailAddress
		});
	}

	setCcasValidateClient(user: Partial<ICcasUser>): Observable<any> {
		return this.http.post<any>(`${this.API_MOBILE_CCAS_V1_SELF_SERVICE_URL}/validate_client`, user);
	}

	getCcasAccountsMe(): Observable<IAccountsMeResult> {
		return this.http.get<any>(`${this.API_MOBILE_CCAS_V1_ACCOUNTS_URL}/me`);
	}

	getMobileHomeSummary(token: string): Observable<IHomeSummaryResult> {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: token
			})
		};
		return this.http.get<any>(`${this.API_MOBILE_URL}/home_summary`, httpOptions);
	}

	claimOffer(token: string): Observable<IClaimOfferResult> {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: token
			})
		};
		return this.http.get<any>(`${this.API_MOBILE_URL}/claim_offer`, httpOptions);
	}

	getSignedLoanDocuments(loanId: number, applicantIndex: number = 0): Observable<any> {
		return this.http.get<any>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/get_signed_loan_documents`,
			{ responseType: 'blob' as 'json' }
		);
	}

	getLoanDocument(language: string, loanId: number, applicantIndex: number = 0): Observable<any> {
		const queryParams = { esignPreferredLanguage: language };
		const params = new HttpParams({ fromObject: { ...queryParams } });
		return this.http.get<any>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/loan_document`,
			{ params }
		);
	}

	updateDisbursementChannel(requestParameters: IDisbursementOptions, loanId: number): Observable<IResult> {
		return this.http.put<IResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/disbursement_channel`, requestParameters);
	}

	updateProductStatus(requestParameters: IProductStatusAction, loanId: number): Observable<IResult> {
		return this.http.put<IResult>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/product-status`, requestParameters);
	}

	getPaymentRemindersOptions(loanId: number, applicantIndex: number = 0): Observable<IPaymentRemindersOptionsResult> {
		return this.http.get<IPaymentRemindersOptionsResult>(
			`${this.API_MOBILE_SERVICING_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/payment_reminders_options`
		);
	}

	getPaymentReminders(loanId: number, applicantIndex: number = 0): Observable<IPaymentRemindersResult> {
		return this.http.get<IPaymentRemindersResult>(
			`${this.API_MOBILE_SERVICING_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/payment_reminders`
		);
	}

	updatePaymentReminders(
		reminders: IPaymentReminders,
		loanId: number,
		applicantIndex: number = 0
	): Observable<IPaymentRemindersResult> {
		return this.http.put<IPaymentRemindersResult>(
			`${this.API_MOBILE_SERVICING_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/payment_reminders`,
			reminders
		);
	}

	updateProductAction(
		requestParameters: IProductAction,
		loanApplicationId: number,
		applicantIndex = 0
	): Observable<IResult> {
		return this.http.put<IResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanApplicationId}/applicants/${applicantIndex}/product-action`,
			requestParameters
		);
	}

	checkMultiProductEligibility(loanApplicationId: number): Observable<IMultiProductResult> {
		return this.http.get<IMultiProductResult>(`${this.API_MOBILE_MULTI_PRODUCT_URL}/${loanApplicationId}/eligibility`);
	}

	setDecryptPrescreenToken(token: string): Observable<any> {
		return this.http.post(`${this.API_MOBILE_V2_LOAN_APP_URL}/prescreen/decrypt`, { token });
	}

	decryptTokenForFastTrackPrequalified(token: string): Observable<IFTPDecryptTokenResult> {
		return this.http.post<IFTPDecryptTokenResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/prequal/decrypt`, { token });
	}

	setDecryptBankConnectToken(token: string): Observable<IBankAccountDecryptTokenResult> {
		return this.http.post<IBankAccountDecryptTokenResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/plaid-connect/decrypt`, {
			token
		});
	}

	setEsignDecrypt(token: string): Observable<IEsignDisclosuresDecryptTokenResult> {
		return this.http.post<IEsignDisclosuresDecryptTokenResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/e-sign/decrypt`, {
			token
		});
	}

	setEsignAccept(token: string): Observable<IEsignDisclosuresDecryptTokenResult> {
		return this.http.post<IEsignDisclosuresDecryptTokenResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/e-sign/accept`, {
			token
		});
	}

	setEsignGet(token: string): Observable<IEsignDisclosuresDecryptTokenResult> {
		return this.http.post<IEsignDisclosuresDecryptTokenResult>(`${this.API_MOBILE_V2_LOAN_APP_URL}/e-sign/get`, {
			token
		});
	}

	getPlaidRefreshEligibility(loanId: number, applicantIndex = 0): Observable<IFeatureEligibilityResult> {
		return this.http.post<IFeatureEligibilityResult>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/eligibility`,
			{
				featureName: AssetRefreshEnum.plaidAssetRefreshConsentFeature
			}
		);
	}

	validatedebitCard(loanId: number, applicantIndex = 0): Observable<IDebitCard> {
		return this.http.get<IDebitCard>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/verify-account`);
	}

	getDebitCard(loanId: number): Observable<IDebitCardDetails> {
		return this.http.get<IDebitCardDetails>(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/debit-account`);
	}

	verifyBankAccounts(accountsList: [], loanId: number): Observable<any> {
		return this.http.post(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/verify_bank_accounts`, {
			bankAccountIds: accountsList
		});
	}

	getSavedAchAccounts(loanId: number, applicantIndex = 0): Observable<IPlaidConnectedInstitutions> {
		return this.http.get<IPlaidConnectedInstitutions>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/plaid-linked-bank-accounts`
		);
	}

	getDebitCardEligibility(
		loanId: number,
		applicantIndex = 0,
		extras = undefined
	): Observable<IFeatureEligibilityResult> {
		return this.getEligibility(FeatureNameEnum.instantDisbursementFeature, loanId, applicantIndex, extras);
	}

	getIncomeVerifiedPlaidEligibility(
		loanId: number,
		applicantIndex = 0,
		extras: object = undefined
	): Observable<IFeatureEligibilityResult> {
		return this.getEligibility(FeatureNameEnum.incomeVerifiedPlaidFeature, loanId, applicantIndex, extras);
	}

	getFraudPushToDebitEligibility(loanId: number, applicantIndex = 0, extras: object = undefined) {
		return this.getEligibility(FeatureNameEnum.fraudPushToDebitFeature, loanId, applicantIndex, extras);
	}

	getEligibility(featureName: string, loanAppId: number, applicantIndex: number = 0, extras: object = undefined) {
		return this.http.post<IFeatureEligibilityResult>(
			`${this.API_MOBILE_V2_LOAN_APP_URL}/${loanAppId}/applicants/${applicantIndex}/eligibility`,
			{
				featureName,
				...extras
			}
		);
	}

	checkDebitCardStatus(loanId: number, token: string): Observable<any> {
		return this.http.post(`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/debit-card`, {
			token
		});
	}

	setUserAccountId(accounts: number) {
		this.userAccounts = accounts;
	}

	getUserAccountId() {
		return this.userAccounts;
	}

	setAchAccountsConsent(
		payload: IConsentRequest,
		loanId: number,
		applicantIndex = 0
	): Observable<IPlaidConnectedInstitutions> {
		return this.http.post<IPlaidConnectedInstitutions>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/applicant_consent`,
			payload
		);
	}

	setRefreshPlaidAccounts(
		payload: IPlaidRefreshRequest,
		loanId: number,
		applicantIndex = 0
	): Observable<IPlaidConnectedInstitutions> {
		return this.http.post<IPlaidConnectedInstitutions>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/plaid-asset-refresh`,
			payload
		);
	}

	getPlaidUserToken(loanId: number, applicantIndex: number = 0): Observable<IPlaidCreateUserResult> {
		const body = {};
		return this.http.post<IPlaidCreateUserResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/plaid/cra-user-token`,
			body
		);
	}

	getPlaidCraDetails(loanId: number, applicantIndex: number = 0): Observable<IPlaidCraDetailsResult> {
		return this.http.get<IPlaidCraDetailsResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${loanId}/applicants/${applicantIndex}/plaid/cra-details`
		);
	}

	getRachEnrollmentEligibility(loanId: number, applicantIndex = 0): Observable<IFeatureEligibilityResult> {
		return this.setEligibility(
			FeatureNameEnum.instantDisbursementRachEnroll,
			null,
			loanId,
			applicantIndex
		) as Observable<IFeatureEligibilityResult>;
	}
}

import { Injectable } from '@angular/core';
import { Event, NavigationEnd } from '@angular/router';
import { isFunction } from 'lodash';

import { ApplicationStatusEnum } from '../loan-application/loan-application.model';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
	providedIn: 'root'
})
export class NewRelicLoggerService {
	constructor(private windowService: WindowRefService) {}

	loanAppId;
	loanAppStatus;
	public setLoanAppInfo(loanAppId: number, loanAppStatus: ApplicationStatusEnum): void {
		this.loanAppId = loanAppId;
		this.loanAppStatus = loanAppStatus;
	}

	public handleError(error: any) {
		isFunction(this.windowService.window?.['NREUM'].noticeError) &&
			this.windowService.window?.['NREUM'].noticeError(error);
	}

	private attachCustomAttributes(custom: any) {
		return {
			loanAppId: this.loanAppId,
			loanAppStatus: this.loanAppStatus,
			...custom
		};
	}

	public logError(msg: string, custom: any) {
		isFunction(this.windowService.window?.['NREUM'].log) &&
			this.windowService.window?.['NREUM'].log(msg, {
				level: 'error',
				customAttributes: this.attachCustomAttributes(custom)
			});
	}

	public logInfo(msg: string, custom: any) {
		isFunction(this.windowService.window?.['NREUM'].log) &&
			this.windowService.window?.['NREUM'].log(msg, {
				level: 'info',
				customAttributes: this.attachCustomAttributes(custom)
			});
	}

	public logDebug(msg: string, custom: any) {
		isFunction(this.windowService.window?.['NREUM'].log) &&
			this.windowService.window?.['NREUM'].log(msg, {
				level: 'debug',
				customAttributes: this.attachCustomAttributes(custom)
			});
	}

	public logRouterEvents(routerEvent: Event) {
		if (routerEvent instanceof NavigationEnd) {
			if (isFunction(this.windowService.window?.['NREUM'].setCurrentRouteName)) {
				this.windowService.window?.['NREUM'].setCurrentRouteName(routerEvent.url);
			}
		}
	}
}

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, OnDestroy } from '@angular/core';
import { distinctUntilChanged, Subscription, tap } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';

import { IApplicant } from '../loan-application/applicant/applicant.model';
import { ILoanApplication } from '../loan-application/loan-application.model';
import { NewRelicLoggerService } from '../new-relic/new-relic-logger.service';
import { NewRelicMonitorService } from '../new-relic/new-relic-monitor.service';
import { LoanApplicationService } from '../loan-application/loan-application.service';

@Injectable({
	providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
	private loanAppService: LoanApplicationService;
	private environmentService: EnvironmentService;
	private newRelicLoggerService: NewRelicLoggerService;
	private newRelicMonitorService: NewRelicMonitorService;
	private subscription: Subscription;

	constructor(private injector: Injector) {
		this.loanAppService = this.injector.get(LoanApplicationService);
		this.environmentService = this.injector.get(EnvironmentService);
		this.newRelicLoggerService = this.injector.get(NewRelicLoggerService);
		this.newRelicMonitorService = this.injector.get(NewRelicMonitorService);

		this.subscription = this.loanAppService.loanApplication$
			.pipe(distinctUntilChanged((prev, curr) => this.isIdsEqual(prev, curr)))
			.subscribe({
				next: (loanApp) => {
					this.newRelicLoggerService.setLoanAppInfo(
						this.loanAppService.loanApplicationId,
						this.loanAppService.applicationStatus
					);
				}
			});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	handleError(err: any): void {
		try {
			// newrelic tracking during error handling
			this.newRelicLoggerService.handleError(err);
			if (this.newRelicMonitorService.getFlowName() && !this.newRelicMonitorService.getFlowError()) {
				this.newRelicMonitorService.setCriticalError(err);
			}
		} catch (e) {
			console.log('error:', e);
		}
	}

	private formatError(error: any): Array<any> {
		if (error instanceof Error) {
			return [error.message, error];
		} else if (error instanceof HttpErrorResponse) {
			return [`Http failure response: ${error.status}`, error];
		} else if (error instanceof String) {
			return [error];
		} else {
			return ['Error', error];
		}
	}

	private isAppIdEqual(a: ILoanApplication, b: ILoanApplication): boolean {
		return a?.id === b?.id;
	}

	private isApplicantIdsEqual(a: ILoanApplication, b: ILoanApplication): boolean {
		const aa = a?.applicants?.length ? a.applicants[0] : ({} as IApplicant);
		const ba = b?.applicants?.length ? b.applicants[0] : ({} as IApplicant);
		return aa.id === ba.id && aa.clientId === ba.clientId;
	}

	private isIdsEqual(a: ILoanApplication, b: ILoanApplication): boolean {
		return this.isAppIdEqual(a, b) && this.isApplicantIdsEqual(a, b);
	}

	private getEnvironment(): string {
		if (this.environmentService.isProduction) return 'production';
		if (this.environmentService.isQa) return 'QA';
		if (this.environmentService.isStage) return 'staging';
		if (this.environmentService.isDev) return 'feature-box';

		return 'development';
	}
}

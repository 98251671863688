import { Injectable } from '@angular/core';

import { NewRelicLoggerService } from '../new-relic/new-relic-logger.service';

@Injectable({
	providedIn: 'root'
})
export class LoggingService {
	constructor(private newRelic: NewRelicLoggerService) {}

	info(msg: string, custom: any = null): void {
		this.newRelic.logInfo(msg, custom);
	}

	debug(msg: string, custom: any = null): void {
		this.newRelic.logDebug(msg, custom);
	}

	error(msg: string, custom: any = null): void {
		this.newRelic.logError(msg, custom);
	}
}

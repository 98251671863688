import { Injectable } from '@angular/core';
import { add, compareAsc, differenceInSeconds, isValid } from 'date-fns';
import { isEmpty } from 'lodash';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { LoanApplicationService } from '../loan-application/loan-application.service';
import { LoggingService } from '../logging/logging.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { SessionStorageService } from '../storage/session-storage.service';

interface IPlaidRestore {
	[key: string]: any;
	ttl: Date;
}

@Injectable({
	providedIn: 'root'
})
export class PlaidRestoreService {
	private readonly storageKey = 'plaidRestore';
	private readonly ttlValue = { minutes: 10 };
	private readonly keyList = [
		'plaid',
		'loanId',
		'auth',
		'productCategorySelection',
		'instrumentation',
		'lang',
		'addressComp',
		'identificationComp'
	];

	constructor(
		private sessionStorageService: SessionStorageService,
		private localStorageService: LocalStorageService,
		private loanAppService: LoanApplicationService,
		private loggingService: LoggingService
	) {
		const restoreData = this.deObfuscator(this.localStorageService.get(this.storageKey, false));
		if (!this.isDataValid(restoreData)) {
			this.removeSessionKey();
		}
	}

	public saveSessionStorage(): void {
		const restoreData = this.keyList.reduce(
			(acc, key) => {
				acc[key] = this.sessionStorageService.get(key);
				return acc;
			},
			{ ttl: add(new Date(), this.ttlValue) }
		);
		this.localStorageService.set(this.storageKey, this.obfuscator(restoreData), false);
	}

	public restoreSessionStorage(): Observable<boolean> {
		const restoreData = this.deObfuscator(this.localStorageService.get(this.storageKey, false));

		if (this.isDataValid(restoreData)) {
			Object.keys(restoreData).forEach((key) => {
				if (key != 'ttl') {
					this.sessionStorageService.set(key, restoreData[key]);
				}
			});

			return this.loanAppService.updateLoanApplication(Number(restoreData.loanId)).pipe(
				map(() => true),
				catchError(() => of(false))
			);
		} else {
			this.removeSessionKey();
			return of(false);
		}
	}

	public removeSessionKey(): void {
		this.localStorageService.remove(this.storageKey);
	}

	private isDataValid(data: IPlaidRestore): boolean {
		if (isEmpty(data)) {
			return false;
		}

		const newDate = new Date();
		const ttl = new Date(data?.ttl);

		if (!isValid(ttl)) {
			return false;
		}

		const valid = compareAsc(ttl, newDate) === 1;
		if (!valid) {
			const diff = differenceInSeconds(newDate, ttl);
			this.loggingService.error('plaid restore ttl expired', { ttl, newDate, diff });
		}
		return valid;
	}

	private obfuscator(data: IPlaidRestore): string {
		return window.btoa(JSON.stringify(data));
	}

	private deObfuscator(data: string): IPlaidRestore {
		if (!isEmpty(data)) {
			const decoded = window.atob(data);
			try {
				return JSON.parse(decoded);
			} catch (err) {
				this.loggingService.error('plaid restore failed', err);
				return null;
			}
		}
	}
}
